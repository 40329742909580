import React from 'react';
import { Link } from 'react-router-dom';

const template = (data) => {
    const context = data.context;
    return (
        <footer>
            <>

            </>
        </footer>
    )
}
export default template;