/**
 * Created by Mark Webley on 05/05/2019.
 * PRESENTATION COMPONENTS | DUMB COMPONANT
 */
import React from "react";
import PropTypes from 'prop-types';
import './course-item.scss';

export class CourseItem extends React.Component {

    state = {
        course: {}
    }

    constructor(props) {
        super(props);
        this.cardBodyRef = React.createRef();
        /*
        this.setState({
            [...this.state, ...this.props]
        });
        */
    }

    cardHeaderExpand = (event) => {
        event.preventDefault();
        event.stopPropagation();
        let nextSibling;
        let headerButton;
        if (event.target.classList.contains('btn-actions')) {
            headerButton = event.target;
            nextSibling = event.target.parentElement.parentElement.nextElementSibling;
        } else {
            headerButton = event.target.querySelector('.btn-actions');
            nextSibling = event.target.parentElement.nextElementSibling;
        }

        document.querySelectorAll('.card-body').forEach((ele) => {
            if (nextSibling !== ele) {
                ele.classList.remove('expanded');
                ele.parentElement.querySelector('.btn-actions').innerText = '-';
            } else {
                nextSibling.classList.toggle('expanded');
                (nextSibling.classList.contains('expanded')) ? headerButton.innerText = '+' : headerButton.innerText = '-';
            }
        });
        /*
        const userName = {...this.state.course, user: event.target.value};
        this.setState({shoppingCart});
        */
    };

    render() {
        let key = 0;
        const {id, title, learningObjectives, topics, handsOn} = this.props.course;
        const allTopics = topics.map((topic) => <li key={key++}><i className="fa fa-play-circle" aria-hidden="true"></i> {topic}</li>);
        return (
            <div key={id} className="card mb-1">
                <a onClick={this.cardHeaderExpand} href={`#/courses/${id}`} className='user-row'>
                    <div className="card-header">{id} {title} <fa className="btn-actions collapse" name="address-card">-</fa></div>
                </a>
                <div className="card-body">
                    <p className="card-text">
                        <span className="sub-heading">Learning Objectives:</span> {learningObjectives}
                        {topics.length ? <><br /><br /><span className="sub-heading">Topics:</span></> : null}
                    </p>
                    <ul>
                        {topics.length ? allTopics : null}
                    </ul>
                    <p>
                        {handsOn.length ? (<><span className="sub-heading">Hands-on:</span> {handsOn}</>) : null}
                    </p>
                </div>
            </div>
        )
    }
}

CourseItem.propTypes = {
    id: PropTypes.number,
    title: PropTypes.string,
    desc: PropTypes.string,
    errors: PropTypes.object
}