/**
 * Created by Mark Webley on 05/05/2019.
 * SMART COMPONENT
 */

import React, {Component} from "react";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {CourseItem} from "./item/course-item";
import {ReactCoursesService} from "../../../services/courses/react-courses.service";
import * as analyticsActions from "../../../redux/actions/storeAnalyticsAction";
import {HeaderPanel} from "./item/header-panel";
import './list-react-courses-component.scss';
import {Loader} from '../../common/loader/loader.component';
import ModalGroupComponent from '../../common/popout-call-to-actions/modal-group.component';

// move to shared class library
class InitaliseState {
    state = {
        loading: true,
        courses: [],
        speil: {
            description: {}
        },
        overview: {},
        prerequities: {},
        requirements: {},
        analytics: {
            user: 'guest',
            userType: 'public',
            dateTimes: '',
            page: '',
            callToAction: ''
        }
    };
}

class ListReactCoursesComponent extends Component {

    state = new InitaliseState().state;

    setCourses(data) {
        const speil = data.speil;
        const overview = data.overview;
        const prerequities = data.prerequities;
        const requirements = data.requirements;
        const courses = [...this.state.courses, ...data.courses];
        this.setState({
            speil,
            overview,
            prerequities,
            requirements,
            courses,
            loading: false
        });
    }

    /** load data services **/
    componentDidMount() {
        if (this.state.courses.length > 0) {
            return;
        }
        ReactCoursesService.getAllCourses().subscribe({
            next: data => {
                this.setCourses(data);
            },
            complete: data => {
                console.log( '[complete]' );
            }
        });
    }

    trackAnalyticsEvents = (page = '', callToAction = '', time = '') => {
        this.setState((state) => {
            return {
                ...state.analytics,
                analytics: { dateTimes: time,
                page,
                callToAction }
            };
        });

        this.props.createAnalytics(this.state.analytics);
    }

    render() {
        let speil = this.state.speil;
        let overview = this.state.overview;
        let prerequities = this.state.prerequities;
        let requirements = this.state.requirements;
        const loader = () => {
            return (
                <Loader />
            )
        };
        const domElements = () => {
            return (
                <>
                    <HeaderPanel sendAnalytics={this.trackAnalyticsEvents} speil={speil.description} />
                    <h1 className="container table-of-contents">Table of Contents</h1>
                    <section className="mw-panel container">
                        <div className="row">
                            <div className="col-xs-12 col-lg-9">
                                {this.state.courses.map((course) => <CourseItem key={course.id} course={course} />)}
                            </div>

                            <ModalGroupComponent/>
                        </div>
                    </section>
                </>
            )
        }
        return (
            <div id="react-course">
                {this.state.loading ? loader(): domElements()}
            </div>
        )
    }
}

ListReactCoursesComponent.propTypes = {
    analytics: PropTypes.object.isRequired,
    createAnalytics: PropTypes.func.isRequired
};

const mapListReactCoursesComponentStateToProps = (state, ownProps) => {
    return {
        analytics: state.analytics
    };
};

const mapDispatchToListReactCoursesComponentToProps = (dispatch) => {
    return {
        createAnalytics: analytics => dispatch(analyticsActions.storeAnalyticsActionCreator(analytics))
    };
};

export default connect(
    mapListReactCoursesComponentStateToProps,
    mapDispatchToListReactCoursesComponentToProps
)(ListReactCoursesComponent);
