/**
 * Created by Mark Webley on 16/05/2019.
 */
import {combineReducers} from 'redux';
import cart from './cartReducer';
import analytics from './storeAnalyticsReducer';

const rootReducer = combineReducers({
    cart: cart,
    analytics: analytics
});

export default rootReducer;