import React, {Component} from 'react';
import {connect} from "react-redux";
import * as analyticsActions from "../../../redux/actions/storeAnalyticsAction";
import PropTypes from "prop-types";

/**
 * Created by Mark Webley on 29/05/2019.
 */
import './modal-group.component.css';

class ModalGroupComponent extends Component {

    state = {
        analytics: this.props.analytics || {}
    }


    /**
     * TODO:
     * changes should happen here
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.analytics !== prevProps.analytics) {
            this.setState((state, props) => {
                debugger;
                console.log('state.analytics:', state.analytics);
                console.log('props.analytics:', props.analytics);
                const newState = {
                    ...this.state.analytics,
                    analytics: props.analytics
                }
                return { analytics: newState};
            });
        }
    }

    /**
     * place into a parent class
     */
    sendAnalytics = () => {

    }

    render() {
        console.log('User (via props): ', this.props.analytics.user);
        console.log('dateTimes (via props)', this.props.analytics.dateTimes);
        return (
            <div id="modal-group-component" className="col-3 d-md-none d-lg-block">
                <div className="panel-item">
                    <div className="header">Instructor lead LIVE TRAINING</div>
                    <ul>
                        <li>24 hrs of Live Sessions</li>
                        <li>Quiz and Assignments</li>
                        <li>Live Projects</li>
                        <li>Learn basic to advanced concept</li>
                    </ul>
                    <span className="btn btn-primary" onMouseUp={this.sendAnalytics}><i className="fa fa-graduation-cap" aria-hidden="true"></i> View Schedule</span>
                </div>

                <div className="panel-item">
                    <div className="header">1-on-1 Training</div>
                    <p>Customized and exclusive training on React</p>
                    <p>Beginner to Experienced developers</p>
                </div>

                <div className="panel-item">
                    <div className="header">Companion Courses</div>
                    <ul className="companion-courses">
                        <li>Javascript ES6</li>
                        <li>TypeScript</li>
                        <li>RxJS</li>
                        <li>Photoshop</li>
                    </ul>
                </div>

            </div>
        )
    }
}


ModalGroupComponent.propTypes = {
    analytics: PropTypes.object.isRequired
};

const mapModalGroupComponentStateToProps = (state, ownProps) => {
    return {
        analytics: state.analytics
    };
};

export default connect(
    mapModalGroupComponentStateToProps
)(ModalGroupComponent);

