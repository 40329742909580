/**
 * Created by Mark Webley on 27/05/2019.
 */
import React from "react";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './header-panel.scss';
import TemplateSummaryListOfCourses from './template-header-panel-courses.jsx';

export class HeaderPanel extends React.Component {

    constructor(props) {
        super(props);
    }

    trackAnalyticsEvents = (event) => {
        debugger;
        return true;
        // this.props.trackAnalyticsEvents(event);
    }

    render() {
        const domContent = () => {
            const listOfTopics = () => {
                return (
                   <TemplateSummaryListOfCourses />
                )
            }
            return (
                <>
                    <h1>Front End Developer Training</h1>
                    <span className="title-subheading">Angular | React | Backbone | Photoshop | Illustrator</span>
                    <p className="summary-description">
                        Courses are lead and taught by industry working tech professionals, using industry tools that are used in the industry today. Our courses are up to date with the propular frameworks that are used by industries across the global IT sector. Our courses are excellent enougth to get newbies up and running to start a new career at an advanced level, and brilliant for existing working professionals looking to sharpen their existing knowledge to help fill in any missing knowledge gaps.
                        For courses for students in corporate environments, please contact us for more information.
                    </p>
                    <div className="topics">
                        <b>Popular Choices:</b>
                        {listOfTopics()}
                    </div>
                    <Link to='/react'  onMouseUp={this.trackAnalyticsEvents} className="btn btn-primary"><i className="fa fa-graduation-cap" aria-hidden="true"></i> 1-on-1 React Courses</Link>
                    <Link to='/angular' onMouseUp={this.trackAnalyticsEvents} className="btn btn-primary"><i className="fa fa-graduation-cap" aria-hidden="true"></i> 1-on-1 Angular Courses</Link>
                    <a className="btn btn-light"><i className="fa fa-commenting" aria-hidden="true"></i> Contact Course Advisor</a>
                </>
            )
        }

        return (
            <div className="jumbotron-fluid">
               <div className="container">
                    {domContent()}
                </div>
            </div>
        )
    }
}