/**
 * Created by Mark Webley on 28/05/2019.
 */
import React from 'react';
import './loader.component.scss';

export const Loader = () => {
    return (
        <div className="page-loading">
            <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
};