/**
 * Created by Mark Webley on 15/05/2019.
 *
 * ONE IMMUTABLE STORE
 * ACTIONS TRIGGER CHANGES
 * REDUCERS UPDATE STATE
 *
   Reducer Function that accepts state and action and returns a new state.
   Do not mutate any states, states are to remain immutable (un changeable)

    whatever is returned from the the reducer becomes the new state.
    if the reducer recieves an action it doesn't care about, it should return the unchanged state.
 */
import * as types from '../actions/allActionTypes';
export default function storeAnalyticsReducer(state = [], action) {
    console.log('state:', state, ' action:', action);
    let newState;
    switch (action.type) {
        case types.STORE_ANALYTICS_DATA:
            newState = ({...state,...action.analytics}); //[...state, {...action.analytics}];
            break;
        case types.SAVE_USER_ITEM:
            newState = ({...state,...action.analytics}); // action = save user
            break;
        case types.CREATE_USER_ITEM:
            newState = ({...state,...action.analytics}); // action = create user
            break;
        case types.DELETE_USER_ITEM:
            newState = ({...state,...action.analytics}); // action = delete user
            break;
        default:
            return state;
    }
    return newState;
}

// actions reducers, state