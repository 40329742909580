// let allPitchesData = require('../stubs/all-pitches').data;
//let allPitchesData = require('../stubs/all-pitches');



import {environment} from '../environment/environment';

// "rxjs": "^6.5.1",
import {Observable} from "rxjs";
import axios from 'axios';

import {ajax} from 'rxjs/ajax';
import combineLatest from 'rxjs/operators/combineLatest';
import debounceTime from 'rxjs/operators/debounceTime';
import startWith from 'rxjs/operators/startWith';
import filter from 'rxjs/operators/filter';
import switchMap from 'rxjs/operators/switchMap';
import {map, catchError, tap} from 'rxjs/operators';
import {of} from 'rxjs';
// import {of} from "rxjs/observable/of";
import BehaviorSubject from "rxjs/BehaviorSubject";
import Subject from "rxjs/Subject";
import {timeout} from "q";





/* "rxjs": "^5.5.6"
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/dom/ajax';
import 'rxjs/add/observable/combineLatest';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/catch';
*/

/***
 * TODO
 *
 *
 * CHANGE THIS CLASS INTO A SINGLETON BUT, MODULES ARE SINGLETONS ALREADY :)
 */

let angularData = require('../../services/stubs/courses/angular.json');
export class AngularCoursesService {

    public static getAllCourses() {
        return new Observable(observer => {
            let interval = setTimeout(() => {
                observer.next(angularData.data);
            }, 1000);
        })
    }

    public static xgetAllCourses() {
        return new Observable(observer => {
            let timer = 0;
            let interval = setInterval(() => {
                if (timer > 10) {
                    clearInterval(interval);
                }
                observer.next({
                    id: Math.floor(Math.random() * 1000),
                    title: 'Title of the course',
                    desc: 'Description of the course, course course course',
                    price: Math.floor(Math.random() * 2500),
                    data: new Date()
                });
                timer++;
                console.log('courses: ', timer);
            }, 1000);
        })
    }

    public static xxgetAllCourses() {
        return Observable.create((observer: Observable<any>) => {
           // return axios.get('https://jsonplaceholder.typicode.com/posts')
           return axios.get('http://appijuice.com/courses/angular.json')
               .then(res => {
                   return res.data;
                  //observer.next(res.data);
                  //observer.complete();
               })
               .catch(error => {
                   //observer.error(error);
                   console.log('Error: ', error);
               });
        });
    }

    private handleError<T> (result?: T) {
        return (error: any): Observable<T | any> => {
            console.error(error);
            if (error.error.errors.length && typeof error.error.errors[0].title !== 'undefined') {
                console.log('error message: ', error.error.errors[0].title);
                // this.broadcastErrorNotification(error.error.errors[0].title);
            }

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

  /*
  public getUsers() {
    return this.http.get<any>(`${environment.API_ENDPOINT.SPORTS}`).pipe(
      tap(
        data => {
          console.log('list sports', data);
        }
      ),
      catchError(this.handleError<any>())
    )
  }

  public broadcastAllUsersChangeNotification(users) {
    console.log('this.listAllUsers>> ', this.listAllUsers);
    this.listAllUsersObserver.next(users);
  }

  public broadcastErrorNotification(message: string = '') {
    this.errorMessageObserver.next(message);
  }


*/
}
