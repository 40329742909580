/**
 * Created by Mark Webley on 28/05/2019.
 */
// template-header-panel-courses.jsx
import React from 'react';
import { Link } from 'react-router-dom';

const template = () => {
    return (
        <div className="row">
            <ul className="col-xs-12 col-sm-4 col-md-3 col-test">
                <li key="1"><i className="fa fa-play-circle" aria-hidden="true"></i> React</li>
                <li key="1"><i className="fa fa-play-circle" aria-hidden="true"></i> Angular</li>
                <li key="1"><i className="fa fa-play-circle" aria-hidden="true"></i> Javascript ES6</li>
                <li key="1"><i className="fa fa-play-circle" aria-hidden="true"></i> RxJS</li>
            </ul>
            <ul className="col-xs-12 col-sm-4 col-md-3 col-test">
                <li key="1"><i className="fa fa-play-circle" aria-hidden="true"></i> Typescript</li>
                <li key="1"><i className="fa fa-play-circle" aria-hidden="true"></i> Ecommerce API's</li>
                <li key="1"><i className="fa fa-play-circle" aria-hidden="true"></i> BootStrap 4</li>
                <li key="1"><i className="fa fa-play-circle" aria-hidden="true"></i> Node.js</li>
            </ul>
            <ul className="col-xs-12 col-sm-4 col-md-3 col-test">
                <li key="1"><i className="fa fa-play-circle" aria-hidden="true"></i> HTML5 / CSS / SASS</li>
                <li key="1"><i className="fa fa-play-circle" aria-hidden="true"></i> Webstorm Intellij</li>
                <li key="1"><i className="fa fa-play-circle" aria-hidden="true"></i> Adobe Photoshop</li>
                <li key="1"><i className="fa fa-play-circle" aria-hidden="true"></i> Adobe Illustrator</li>
                {/* <li key="1"><i class="fa fa-play-circle" aria-hidden="true"></i> Adobe After Effects</li> */}
            </ul>
        </div>
    )
}
export default template;