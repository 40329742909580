/**
 * Created by Mark Webley on 15/05/2019.
 */
import * as types from './allActionTypes';
export function storeAnalyticsActionCreator(analytics) {
    return {
        type: types.STORE_ANALYTICS_DATA,
        analytics: analytics
    };
}

export function saveAnalyticsActionCreator(analytics) {
    return {
        type: types.SAVE_ANALYTICS_DATA,
        analytics: analytics
    };
}

export function storeClickDataAnalyticsActionCreator(analytics) {
    return {
        type: types.STORE_ANALYTICS_CLICK_DATA,
        analytics: analytics
    };
}