/**
 * Created by Mark Webley on 16/05/2019.
 */
export const CREATE_USER_ITEM = 'CREATE_USER_ITEM';
export const SAVE_USER_ITEM = 'SAVE_USER_ITEM';
export const DELETE_USER_ITEM = 'DELETE_USER_ITEM';

export const SAVE_ANALYTICS_DATA = 'SAVE_ANALYTICS_DATA';
export const STORE_ANALYTICS_DATA = 'STORE_ANALYTICS_DATA';
export const STORE_ANALYTICS_CLICK_DATA = 'STORE_ANALYTICS_CLICK_DATA';

export const CREATE_CART_ITEM = 'CREATE_CART_ITEM';
export const SAVE_CART_ITEM = 'SAVE_CART_ITEM';
export const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM';
export const CANCEL_USER_ITEM = 'CANCEL_USER_ITEM';
export const DELETE_CART_ITEM = 'DELETE_CART_ITEM';


export const PAGE_LOADING = 'PAGE_LOADING';
export const COMPONENT_LOADING = 'COMPONENT_LOADING';
export const PARTIAL_ITEM_LOADING = 'PARTIAL_ITEM_LOADING';
export const PAGE_LOADING_COMPLETE = 'PAGE_LOADING_COMPLETE';



