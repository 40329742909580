/**
 * Created by Mark Webley on 28/05/2019.
 */
import React, {Component} from "react";
import { Link } from 'react-router-dom';
import {HeaderPanel} from "./components/header-panel";
import './home.component.scss';

export class Home extends Component {

    trackAnalyticsEvents = (event) => {
        debugger;
    }

    render() {
        return(
            <div id="home-page">
                <HeaderPanel  />
                <h1 className="container"></h1>
                <section className="mw-panel container">
                    { /*
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-test">
                            <div className="course-bg"> massive image asfafs</div>
                            <Link to='/react' className="btn btn-primary call-to-action">Get Started</Link>
                        </div>
                        <div className="col-sm-12 col-md-6 col-test">
                            <div className="course-bg"> massive image asfafs</div>
                            <Link to='/angular' className="btn btn-primary call-to-action">Get Started</Link>
                        </div>
                    </div>
                    */ }
                </section>

                <section className="mw-panel container">
                    <div className="row">
                        <div className="col-12 testimonials">
                            <span className="heading">Testimonial</span>
                            <div className="testimony">excellent approach to explaining things <br />made it very easy for me to understand & get up to speed very quickly.</div>
                            <span className="witness">-back end developer-</span>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}