import React from 'react';
import './App.css';
import AppRoutes from './app.routes';
import FooterComponent from "./modules/app-container/footer/footer.component";

function App() {
  return (
    <div className="App">
        <AppRoutes />
        <FooterComponent />
    </div>
  );
}

export default App;
