/**
 * Created by Mark Webley on 05/05/2019.
 */
import React, {Component} from 'react';
import {Switch, Route, BrowserRouter} from 'react-router-dom';
import configureStore from './redux/configureStore';
import ListReactCoursesComponent from "./modules/courses/react/list-react-courses.component";
import ListAngularCoursesComponent from "./modules/courses/angular/list-angular-courses.component";
import {Home} from "./modules/home/home.component";


class AppRoutes extends Component {
    render() {
        return (
            <BrowserRouter >
                <main>
                    <Switch>
                        <Route exact path='/' component={Home} />
                        { /* <Route path='/courses' component={ListAngularCoursesComponent} /> */ }
                        <Route path='/angular' component={ListAngularCoursesComponent} />
                        <Route path='/react' component={ListReactCoursesComponent} />
                        { /* <Route path='/javascript' component={ListReactCoursesComponent} />
                            <Route path='/shop' component={ListReactCoursesComponent} /> */ }
                    </Switch>
                </main>
            </BrowserRouter>
        );
    }
}
export default AppRoutes;