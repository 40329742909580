/**
 * Created by Mark Webley on 15/05/2019.
 *
 * ONE IMMUTABLE STORE
 * ACTIONS TRIGGER CHANGES
 * REDUCERS UPDATE STATE
 *
   Reducer Function that accepts state and action and returns a new state.
   Do not mutate any states, states are to remain immutable (un changeable)

    whatever is returned from the the reducer becomes the new state.
    if the reducer recieves an action it doesn't care about, it should return the unchanged state.
 */

// {id: 1, title: "Course 1"}
// {id: 2, title: "Course 2"}

import * as types from '../actions/allActionTypes';
export default function cartReducer(state = [], action) {
    let newState;
    switch (action.type) {
        case types.CREATE_CART_ITEM:
            newState = [...state, {...action.cartItem}];
            break;
        case types.SAVE_CART_ITEM:
            newState = [...state, {...action.cartItem}];
            break;
        case types.UPDATE_CART_ITEM:
            newState = [...state, {...action.cartItem}];
            break;
        case types.DELETE_CART_ITEM:
            newState = [...state, {...action.cartItem}];
            break;
        default:
            return state;
    }
    return newState;
}

// actions reducers, state