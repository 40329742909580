/**
 * Created by Mark Webley on 27/05/2019.
 */
import React from "react";
import PropTypes from 'prop-types';
import * as moment from 'moment';
import './header-panel.scss';

/**
 * PRESENTATION COMPONENT
 */
export class HeaderPanel extends React.Component {

    constructor(props) {
        super(props);
    }

    sendAnalytics = (event) => {
        const now = moment().format('DD/MM/YYYY LTS');
        this.props.sendAnalytics('react-page:top-banner', `btn:${event.target.innerText}`, now);
    }

    render() {
        const domContent = () => {
            if (this.props === null) {
                return (<div>loading</div>);
            } else {
                const {title, location, description, topics} = this.props.speil;
                let key = 0;
                const listOfTopics = () => {
                    if (topics) {
                        return (
                            <ul>
                                {topics.map(topic => <li key={key++}><i className="fa fa-play-circle-o"></i> {topic}</li>)}
                            </ul>
                        )
                    } else {
                        return <></>
                    }
                }
                return (
                    <>
                        <h1>{title} <span className="title-subheading">{location}</span></h1>
                        <p className="summary-description">{description}</p>
                        <div className="topics">
                        {listOfTopics()}
                        </div>
                        <span className="btn btn-primary" onMouseUp={this.sendAnalytics}><i className="fa fa-graduation-cap" aria-hidden="true"></i> View Schedule</span>
                        <span className="btn btn-light" onMouseUp={this.sendAnalytics}><i className="fa fa-commenting" aria-hidden="true"></i> Contact Course Advisor</span>
                    </>
                )
            }
        }

        let key = 0;
        return (
            <div className="jumbotron-fluid">
                <div className="container">
                    {domContent()}
                </div>
            </div>
        )
    }
}

HeaderPanel.protoTypes  = {
    title: PropTypes.string.isRequired,
    location: PropTypes.string,
    description: PropTypes.string,
    topics: PropTypes.object
}