/**
 * Created by WebEntra on 07/01/2018.
 */
import React from 'react';
import Template from './template';
import 'font-awesome-sass-loader';
import 'bootstrap';
//import './style.scss';

// The Header creates links that can be used to navigate
// between routes.
class FooterComponent extends React.Component {
    constructor (props) {
        super(props);
        //this.toggleNavigation = this.toggleNavigation.bind(this, event);
    }

    toggleNavigation(e) {
        e.preventDefault();
        document.querySelector('.navbar-collapse').classList.toggle('collapse');
        document.querySelector('nav').classList.toggle('border-effect');
    }

    toggleMenuButton(e){
        e.preventDefault();
        const navNode = document.querySelector('nav');
        document.querySelector('.navbar-collapse').classList.toggle('collapse');
        if (navNode.classList.contains('border-effect')){
            navNode.classList.remove('border-effect');
        }
    }

    render () {
        return (
            <Template context={this} />
        );
    }
}

export default FooterComponent;